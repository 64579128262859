import { DISCOUNTS } from '~/utils/lists/club-discounts'
import { TAGS } from '~/utils/lists/order-tags'

// Gets a club member's best discount from their active subscriptions.
export const getClubDiscountFromSubscriptions = (subscriptions) =>
  subscriptions
    ?.filter(({ state }) => state === 'active')
    ?.reduce((bestDiscount, subscription) => {
      return subscription?.tagList?.includes(TAGS.CLUB_24_NEW)
        ? DISCOUNTS.CLUB_24
        : Math.max(DISCOUNTS.CLUB_12, bestDiscount)
    }, 0)
