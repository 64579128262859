import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/referrals'
import {
  getReferralsHasHiddenBubble,
  getReferralsHasPurchased,
} from '~/redux/selectors/referrals'

export function useReferrals() {
  const dispatch = useDispatch()
  const referralsHasHiddenBubble = useSelector(getReferralsHasHiddenBubble)
  const referralsHasPurchased = useSelector(getReferralsHasPurchased)

  const setReferralsHasHiddenBubble = useMemo(
    () => bindActionCreators(actions.setReferralsHasHiddenBubble, dispatch),
    [dispatch]
  )

  const setReferralsHasPurchased = useMemo(
    () => bindActionCreators(actions.setReferralsHasPurchased, dispatch),
    [dispatch]
  )

  return {
    referralsHasHiddenBubble,
    referralsHasPurchased,
    setReferralsHasHiddenBubble,
    setReferralsHasPurchased,
  }
}
