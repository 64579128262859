/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useAria } from '~/hooks/components/use-aria'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'

const AriaLive = () => {
  const { ariaLive } = useAria()

  return (
    <VisuallyHidden>
      <Box aria-live="assertive">{ariaLive}</Box>
    </VisuallyHidden>
  )
}

export default AriaLive
