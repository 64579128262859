import initialState from '~/redux/store/initial-state'

const catalog = (state = initialState.catalog, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default catalog
