import { useSelector } from 'react-redux'
import { getCatalog } from '~/redux/selectors/catalog'

export function useCatalog() {
  const catalog = useSelector(getCatalog)

  return {
    catalog,
  }
}
