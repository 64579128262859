import { ARIA_LIVE_UPDATE } from '~/redux/actions/types'

export const updateAriaLive = (message) => {
  return {
    type: ARIA_LIVE_UPDATE,
    payload: {
      message,
    },
  }
}
