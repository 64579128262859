export const ALERT_BAR_DISMISS = 'ALERT_BAR_DISMISS'
export const ALERT_BAR_UPDATE = 'ALERT_BAR_UPDATE'
export const ARIA_LIVE_UPDATE = 'ARIA_LIVE_UPDATE'
export const CAN_CLUB_SET_CLUB_DISCOUNT = 'CAN_CLUB_SET_CLUB_DISCOUNT'
export const CAN_CLUB_CUSTOMIZATION_SET_ERROR =
  'CAN_CLUB_CUSTOMIZATION_SET_ERROR'
export const CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED =
  'CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED'
export const CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING =
  'CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING'
export const CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR =
  'CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR'
export const CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID =
  'CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID'
export const CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST =
  'CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST'
export const CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS =
  'CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS'
export const CAN_CLUB_JOIN_SET_INTERVAL = 'CAN_CLUB_JOIN_SET_INTERVAL'
export const CAN_CLUB_JOIN_SET_STEP = 'CAN_CLUB_JOIN_SET_STEP'
export const CAN_CLUB_JOIN_SET_TYPE = 'CAN_CLUB_JOIN_SET_TYPE'
export const CAN_CLUB_SET_PRODUCTS = 'CAN_CLUB_SET_PRODUCTS'
export const CAN_CLUB_SET_PRODUCTS_DEFAULTS = 'CAN_CLUB_SET_PRODUCTS_DEFAULTS'
export const CAN_CLUB_SET_QUANTITY = 'CAN_CLUB_SET_QUANTITY'
export const CAN_CLUB_UPDATE_PRODUCT_QUANTITY =
  'CAN_CLUB_UPDATE_PRODUCT_QUANTITY'
export const CART_FLYOUT_CLOSE = 'CART_FLYOUT_CLOSE'
export const CART_FLYOUT_OPEN = 'CART_FLYOUT_OPEN'
export const CART_FLYOUT_TOGGLE = 'CART_FLYOUT_TOGGLE'
export const GEOLOCATION_FETCH_REQUEST = 'GEOLOCATION_FETCH_REQUEST'
export const GEOLOCATION_FETCH_SUCCESS = 'GEOLOCATION_FETCH_SUCCESS'
export const GEOLOCATION_FETCH_ERROR = 'GEOLOCATION_FETCH_ERROR'
export const GEOLOCATION_WARNING_ENABLE = 'GEOLOCATION_WARNING_ENABLE'
export const GEOLOCATION_WARNING_DISABLE = 'GEOLOCATION_WARNING_DISABLE'
export const GIFT_CARDS_DETAILS_ADD = 'GIFT_CARDS_DETAILS_ADD'
export const GIFT_CARDS_DETAILS_REMOVE = 'GIFT_CARDS_DETAILS_REMOVE'
export const GROUP_ORDER_REQUEST_ESTIMATE = 'GROUP_ORDER_REQUEST_ESTIMATE'
export const GROUP_ORDER_SET_ERROR = 'GROUP_ORDER_SET_ERROR'
export const GROUP_ORDER_SUBMIT = 'GROUP_ORDER_SUBMIT'
export const GROUP_ORDER_UPDATE_ADDONS = 'GROUP_ORDER_UPDATE_ADDONS'
export const GROUP_ORDER_UPDATE_ADDRESSES = 'GROUP_ORDER_UPDATE_ADDRESSES'
export const GROUP_ORDER_UPDATE_DETAILS = 'GROUP_ORDER_UPDATE_DETAILS'
export const GROUP_ORDER_UPDATE_PRODUCTS = 'GROUP_ORDER_UPDATE_PRODUCTS'
export const PRODUCT_FILTERS_ADD = 'PRODUCT_FILTERS_ADD'
export const PRODUCT_FILTERS_REMOVE = 'PRODUCT_FILTERS_REMOVE'
export const REFERRALS_SET_HAS_HIDDEN_BUBBLE = 'REFERRALS_SET_HAS_HIDDEN_BUBBLE'
export const REFERRALS_SET_HAS_PURCHASED = 'REFERRALS_SET_HAS_PURCHASED'
export const REVIEWS_ALL_REVIEWS_REQUEST = 'REVIEWS_ALL_REVIEWS_REQUEST'
export const REVIEWS_ALL_REVIEWS_SUCCESS = 'REVIEWS_ALL_REVIEWS_SUCCESS'
export const REVIEWS_PRODUCT_REVIEWS_REQUEST = 'REVIEWS_PRODUCT_REVIEWS_REQUEST'
export const REVIEWS_PRODUCT_REVIEWS_SUCCESS = 'REVIEWS_PRODUCT_REVIEWS_SUCCESS'
export const REVIEWS_PRODUCTS_STATS_REQUEST = 'REVIEWS_PRODUCTS_STATS_REQUEST'
export const REVIEWS_PRODUCTS_STATS_SUCCESS = 'REVIEWS_PRODUCTS_STATS_SUCCESS'
export const REVIEWS_SITE_STATS_REQUEST = 'REVIEWS_SITE_STATS_REQUEST'
export const REVIEWS_SITE_STATS_SUCCESS = 'REVIEWS_SITE_STATS_SUCCESS'
