import {
  REVIEWS_ALL_REVIEWS_REQUEST,
  REVIEWS_ALL_REVIEWS_SUCCESS,
  REVIEWS_PRODUCT_REVIEWS_REQUEST,
  REVIEWS_PRODUCT_REVIEWS_SUCCESS,
  REVIEWS_PRODUCTS_STATS_REQUEST,
  REVIEWS_PRODUCTS_STATS_SUCCESS,
  REVIEWS_SITE_STATS_REQUEST,
  REVIEWS_SITE_STATS_SUCCESS,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const reviews = (state = initialState.reviews, action) => {
  switch (action.type) {
    case REVIEWS_ALL_REVIEWS_REQUEST:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          fetched: [...state.reviews.fetched, 'all'],
        },
        stats: {
          ...state.stats,
          all: {
            ...state.stats.all,
            hasFetched: true,
          },
        },
      }

    case REVIEWS_ALL_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          data: {
            ...state.reviews.data,
            all: [...action.payload.data?.reviews],
          },
        },
        stats: {
          ...state.stats,
          all: {
            ...state.stats.all,
            data: {
              ...state.stats.all.data,
              ...action.payload.data?.bottomline,
            },
          },
        },
      }

    case REVIEWS_PRODUCT_REVIEWS_REQUEST:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          fetched: [...state.reviews.fetched, action.payload.id],
        },
      }

    case REVIEWS_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          data: {
            ...state.reviews.data,
            [action.payload.id]: [...action.payload.data?.reviews],
          },
        },
      }

    case REVIEWS_PRODUCTS_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          products: {
            ...state.stats.products,
            hasFetched: true,
          },
        },
      }

    case REVIEWS_PRODUCTS_STATS_SUCCESS:
      const bottomlines = {}

      action.payload.data.data.forEach((product) => {
        bottomlines[product.domain_key] = {
          count: product.total_reviews,
          score: product.product_score,
        }
      })

      return {
        ...state,
        stats: {
          ...state.stats,
          products: {
            ...state.stats.products,
            data: {
              ...bottomlines,
            },
          },
        },
      }

    case REVIEWS_SITE_STATS_REQUEST:
      return {
        ...state,
        stats: {
          ...state.stats,
          site: {
            ...state.stats.site,
            hasFetched: true,
          },
        },
      }

    case REVIEWS_SITE_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          site: {
            ...state.stats.site,
            data: {
              count: action?.payload?.data?.data?.total_reviews,
              score: action?.payload?.data?.data?.average_score,
            },
          },
        },
      }

    default:
      return state
  }
}

export default reviews
