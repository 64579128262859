import {
  REFERRALS_SET_HAS_HIDDEN_BUBBLE,
  REFERRALS_SET_HAS_PURCHASED,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const referrals = (state = initialState.referrals, action) => {
  switch (action.type) {
    case REFERRALS_SET_HAS_HIDDEN_BUBBLE:
      return {
        ...state,
        hasHiddenBubble: action.payload.data,
      }

    case REFERRALS_SET_HAS_PURCHASED:
      return {
        ...state,
        hasPurchased: action.payload.data,
      }

    default:
      return state
  }
}

export default referrals
