import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { useAddToCart } from '~/hooks/components/use-add-to-cart'
import { useCanCount } from '~/hooks/components/use-can-count'
import { getCatalog } from '~/redux/selectors/catalog'

export function useBuyXGetOne() {
  // The number of cans to look for and the SKU to add to the cart.
  const bxgoCount = process.env.GATSBY_BXGO_COUNT
  const bxgoSku = process.env.GATSBY_BXGO_SKU

  const {
    addToCart,
    cart: { lineItems } = {},
    isFetching,
    removeFromCart,
  } = useCart()

  const { getAddToCartDataBySku } = useAddToCart()
  const { parts } = getAddToCartDataBySku(bxgoSku) || {}

  const canCount = useCanCount([bxgoSku])

  // Provides the ability to enable/disable BXGO with Contentful publishing.
  const catalog = useSelector(getCatalog)
  const isBxgoActive =
    bxgoCount &&
    bxgoSku &&
    [...catalog?.kits, ...catalog?.variants].filter((kv) => kv.sku === bxgoSku)
      .length > 0

  useEffect(() => {
    const updateBxgoCart = async () => {
      // Determine if the bxgo SKU is already in the cart.
      const bxgoLineItemId = lineItems?.reduce(
        (id, lineItem) =>
          lineItem?.variant?.sku === bxgoSku ? lineItem.id : id,
        null
      )

      if ((!isBxgoActive || canCount < bxgoCount) && bxgoLineItemId) {
        await removeFromCart({ lineItemId: bxgoLineItemId })
      } else if (isBxgoActive && canCount >= bxgoCount && !bxgoLineItemId) {
        await addToCart({
          lineItem: { sku: bxgoSku, quantity: 1, options: { parts } },
        })
      }
    }

    if (bxgoSku && !isFetching) updateBxgoCart()
  }, [canCount, isFetching]) // eslint-disable-line

  return {}
}
