import {
  CART_FLYOUT_CLOSE,
  CART_FLYOUT_OPEN,
  CART_FLYOUT_TOGGLE,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const cartFlyout = (state = initialState.cartFlyout, action) => {
  switch (action.type) {
    case CART_FLYOUT_CLOSE:
      return {
        ...state,
        isOpen: false,
      }

    case CART_FLYOUT_OPEN:
      return {
        ...state,
        isOpen: true,
      }

    case CART_FLYOUT_TOGGLE:
      return {
        ...state,
        isOpen: !state.isOpen,
      }

    default:
      return state
  }
}

export default cartFlyout
