import axios from 'axios'
import {
  analytics,
  getAnonymousId,
} from '@chordcommerce/chord-js-autonomy/dist/analytics/utils'

const prepareClubItems = (items) => {
  const skus = Object.keys(items)
  const preparedItems = []

  for (let i = 0; i < skus.length; i++) {
    const quantity = items[skus[i]].quantity

    if (quantity > 0) {
      preparedItems.push({
        sku: skus[i],
        quantity: items[skus[i]].quantity,
      })
    }
  }

  return preparedItems
}

const track = (name, properties) =>
  new Promise((resolve, reject) => {
    try {
      const a = analytics()
      if (a) {
        a.track(name, {
          brand: process.env.GATSBY_CHORD_OMS_BRAND_NAME,
          ...properties,
        })
      }

      return resolve()
    } catch (error) {
      return reject(error)
    }
  })

// Fires event server side to avoid tracking interference.
const trackViaProxy = async (name, properties, user) =>
  await axios.post('/.netlify/functions/event', {
    name,
    properties,
    userId: user?.data?.id,
    anonymousId: getAnonymousId(),
  })

const trackClubPaymentMethodUpdated = async ({ subscriptionId, user }) =>
  await trackViaProxy(
    'Club Payment Method Updated',
    {
      email: user?.data?.email,
      subscription_id: subscriptionId,
    },
    user
  )

const trackClubCustomizeSaved = ({ items, user }) =>
  track('Club Customize Saved', {
    email: user?.data?.email,
    items: prepareClubItems(items),
  })

const trackClubCustomizeStarted = ({ items, subscriptionId, type }) =>
  track('Club Customize Started', {
    items: prepareClubItems(items),
    subscriptionId,
    type,
  })

const trackClubCustomizeUpdated = ({ updated, items }) =>
  track('Club Customize Updated', {
    updated,
    items: prepareClubItems(items),
  })

const trackClubDefaultSelectionStarted = ({ type }) =>
  track('Club Default Selection Started', {
    type,
  })

const trackClubJoinStarted = () => track('Club Join Started', {})

const trackClubReviewStarted = ({ items, type }) =>
  track('Club Review Started', {
    items: prepareClubItems(items),
    type,
  })

const trackClubSignupCompleted = ({ cart }) =>
  track('Club Signup Completed', {
    email: cart?.email,
    transaction_id: cart?.number,
  })

const trackReferralRequest = async (data, user) =>
  await trackViaProxy(
    'Referral Request Submitted',
    {
      referral_identifier: data.referralIdentifier,
      referred_email: data.referredEmail,
      referrer_email: data.referrerEmail,
      referrer_name: data.referrerName,
    },
    user
  )

export {
  track,
  trackClubCustomizeSaved,
  trackClubCustomizeStarted,
  trackClubCustomizeUpdated,
  trackClubDefaultSelectionStarted,
  trackClubJoinStarted,
  trackClubPaymentMethodUpdated,
  trackClubReviewStarted,
  trackClubSignupCompleted,
  trackReferralRequest,
}
