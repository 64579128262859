export const getClubDiscount = (state) => state?.canClub?.clubDiscount

export const getCustomizationError = (state) =>
  state?.canClub?.customization?.error

export const getCustomizationIsCustomized = (state) =>
  state?.canClub?.customization?.isCustomized

export const getCustomizationIsSaving = (state) =>
  state?.canClub?.customization?.isSaving

export const getCustomizationShowError = (state) =>
  state?.canClub?.customization?.showError

export const getCustomizationVariantsIsFetching = (state) =>
  state?.canClub?.customization?.variants?.isFetching

export const getCustomizationVariantsIds = (state) =>
  state?.canClub?.customization?.variants?.ids

export const getJoinInterval = (state) => state?.canClub?.join?.interval

export const getJoinStep = (state) => state?.canClub?.join?.step

export const getJoinSteps = (state) => state?.canClub?.join?.steps

export const getJoinType = (state) => state?.canClub?.join?.type

export const getProducts = (state) => state?.canClub?.products?.data

export const getProductsDefaults = (state) => state?.canClub?.products?.defaults

export const getProductsIsSet = (state) => state?.canClub?.products?.isSet

export const getQuantity = (state) => state?.canClub?.quantity
