import {
  PRODUCT_FILTERS_ADD,
  PRODUCT_FILTERS_REMOVE,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const productFilters = (state = initialState.productFilters, action) => {
  switch (action.type) {
    case PRODUCT_FILTERS_ADD:
      return {
        ...state,
        [action.payload.set]: {
          ...state[action.payload.set],
          [action.payload.category]: [
            ...state[action.payload.set][action.payload.category],
            action.payload.value,
          ],
        },
      }

    case PRODUCT_FILTERS_REMOVE:
      return {
        ...state,
        [action.payload.set]: {
          ...state[action.payload.set],
          [action.payload.category]: state[action.payload.set][
            action.payload.category
          ].filter((value) => value !== action.payload.value),
        },
      }

    default:
      return state
  }
}

export default productFilters
