import React, { Fragment } from 'react'
import GeolocationWrapper from '~/components/Geolocation'
import GoaffproScript from '~/components/Goaffpro/Script'
import AriaLive from '~/components/Aria/Live'
import LayoutWrapperClubDiscount from '~/components/Layout/ClubDiscount'
import { useBuyXGetOne } from '~/hooks/components/use-buy-x-get-one'
import { useOutdatedBundleDetection } from '~/hooks/components/use-outdated-bundle-detection'
import { useUrlPromoCodes } from '~/hooks/components/use-promo-codes'
import { setShareasaleId } from '~/services/shareasale/client'

const LayoutWrapper = ({ children }) => {
  setShareasaleId()
  useBuyXGetOne()
  useOutdatedBundleDetection()
  useUrlPromoCodes()

  return (
    <Fragment>
      {children}
      <LayoutWrapperClubDiscount />
      <GeolocationWrapper />
      <GoaffproScript />
      <AriaLive />
    </Fragment>
  )
}

export default LayoutWrapper
