import Cookies from 'js-cookie'
import {
  REFERRALS_SET_HAS_HIDDEN_BUBBLE,
  REFERRALS_SET_HAS_PURCHASED,
} from '~/redux/actions/types'
import storage from '~/services/storage'
import {
  REFERRALS_COOKIE_HAS_PURCHASED,
  REFERRALS_STORAGE_BUBBLE_HIDDEN,
} from '~/utils/referrals'

export const referralsMiddleware = (store) => (next) => (action) => {
  let result = next(action)
  let nextState = store.getState()

  switch (action.type) {
    case REFERRALS_SET_HAS_HIDDEN_BUBBLE:
      storage.setItem(REFERRALS_STORAGE_BUBBLE_HIDDEN, true)
      break

    case REFERRALS_SET_HAS_PURCHASED:
      // Used within Attentive to hide sign-up units (that's why it uses a
      // cookie rather than local storage).
      Cookies.set(REFERRALS_COOKIE_HAS_PURCHASED, true, {
        expires: 365,
        path: '/',
      })
      break

    default:
      return nextState
  }

  return result
}
