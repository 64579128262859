import React from 'react'
import { StytchClient } from '@chordcommerce/chord-stytch'
import { ChordProvider as BaseChordProvider } from '@chordcommerce/react-autonomy'
import { useCatalogQuery } from '~/hooks/graphql/queries/use-catalog'
import { authMiddleware } from '~/redux/middleware/auth'
import { canClubMiddleware } from '~/redux/middleware/can-club'
import { groupOrderMiddleware } from '~/redux/middleware/group-order'
import { referralsMiddleware } from '~/redux/middleware/referrals'
import alertBar from '~/redux/reducers/alert-bar'
import aria from '~/redux/reducers/aria'
import canClub from '~/redux/reducers/can-club'
import cartFlyout from '~/redux/reducers/cart-flyout'
import catalog from '~/redux/reducers/catalog'
import geolocation from '~/redux/reducers/geolocation'
import giftCardDetails from '~/redux/reducers/gift-card-details'
import groupOrder from '~/redux/reducers/group-order'
import productFilters from '~/redux/reducers/product-filters'
import referrals from '~/redux/reducers/referrals'
import reviews from '~/redux/reducers/reviews'
import initialMakerState from '~/redux/store/initial-state'

const ReduxProvider = ({ children, options }) => {
  const chordConfigs = {
    ...options.omsConfig,
    currency: 'USD',
    locale: 'en-US',
  }

  const stytchClient = new StytchClient(options.authConfig.stytch)

  const initialCatalogState = useCatalogQuery()

  return (
    <BaseChordProvider
      config={chordConfigs}
      auth={stytchClient}
      reducer={{
        alertBar,
        aria,
        canClub,
        cartFlyout,
        catalog,
        geolocation,
        giftCardDetails,
        groupOrder,
        productFilters,
        referrals,
        reviews,
      }}
      preloadedState={{ ...initialMakerState, ...initialCatalogState }}
      middleware={[
        authMiddleware,
        canClubMiddleware,
        groupOrderMiddleware,
        referralsMiddleware,
      ]}
    >
      {children}
    </BaseChordProvider>
  )
}

export default ReduxProvider
