import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { getCatalog } from '~/redux/selectors/catalog'

export function useCanCount(kitSkusToExclude = []) {
  const catalog = useSelector(getCatalog)
  const { cart: { lineItems, total } = {} } = useCart()
  const [canCount, setCanCount] = useState(null)

  // Helps count only single can kit parts.
  const singleCanVariants = catalog?.variants
    ?.filter((variant) => variant?.optionValues?.[0]?.slug === 'quantity-1')
    .map((variant) => variant.sku)

  useEffect(() => {
    const countCans = async () => {
      if (!lineItems || !lineItems.length) return setCanCount(0)

      setCanCount(
        lineItems.reduce((lineItemCans, lineItem) => {
          const lineItemSku = lineItem?.variant?.sku

          // The SKU should not be counted.
          if (kitSkusToExclude.includes(lineItemSku)) return lineItemCans

          // The SKU is a kit with part line items.
          if (lineItem?.parts?.length) {
            return (
              lineItemCans +
              lineItem?.parts?.reduce(
                (partCans, part) =>
                  singleCanVariants.includes(part.sku)
                    ? partCans + lineItem.quantity * part.quantity
                    : partCans,
                0
              )
            )
          }

          // The SKU is a single product/variant (e.g. Can Club signup).
          if (singleCanVariants.includes(lineItemSku)) {
            return lineItemCans + lineItem.quantity
          }

          return lineItemCans
        }, 0)
      )
    }

    countCans()
  }, [total]) // eslint-disable-line

  return canCount
}
