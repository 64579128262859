export const isColorDark = (color) => {
  if (!color) return false

  const hex = color.replace('#', '')
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)
  const brightness = (r * 299 + g * 587 + b * 114) / 1000

  return brightness < 155
}

export const isProduction = () =>
  process.env.GATSBY_CHORD_OMS_API_URL.includes('maker-wine.assembly-api.com')
