import moment from 'moment'

export const getSubscriptionCustomizableStartDate = (subscription) => {
  const { actionableDate } = subscription
  return moment(actionableDate).subtract(14, 'days')
}

export const getSubscriptionCustomizableEndDate = (subscription) => {
  const { actionableDate } = subscription
  return moment(actionableDate)
}

export const getIsSubscriptionCustomizable = (subscription) => {
  const customizeStartDate = getSubscriptionCustomizableStartDate(subscription)

  return (
    !!subscription?.reminders?.find(
      ({ reminderType, sentAt }) =>
        reminderType === 'subscription_default_reminder' &&
        sentAt !== null &&
        moment().subtract(2, 'weeks').isBefore(moment(sentAt)) &&
        customizeStartDate.subtract(4, 'hours').isBefore(moment())
    ) || customizeStartDate.isBefore(moment())
  )
}
