import { graphql, useStaticQuery } from 'gatsby'

export const useCatalogQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPack {
        nodes {
          ...CatalogPackFragment
        }
      }
      allContentfulMerch {
        nodes {
          ...CatalogMerchFragment
        }
      }
      allContentfulKit {
        nodes {
          ...CatalogKitFragment
        }
      }
      allContentfulProduct {
        nodes {
          ...CatalogProductFragment
        }
      }
      allContentfulVariant {
        nodes {
          ...CatalogVariantFragment
        }
      }
      allContentfulSubscription {
        nodes {
          ...SubscriptionFragment
        }
      }
    }
  `)

  return {
    catalog: {
      packs: data.allContentfulPack.nodes,
      merch: data.allContentfulMerch.nodes,
      kits: data.allContentfulKit.nodes,
      products: data.allContentfulProduct.nodes,
      variants: data.allContentfulVariant.nodes,
      subscriptions: data.allContentfulSubscription.nodes,
    },
  }
}
