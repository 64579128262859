import { TAGS } from '~/utils/lists/order-tags'

// Gets the Contenful subscription content from the tags on an OMS subscription.
export const getSubscriptionContentFromTags = (
  subscription,
  allSubscriptionContent
) =>
  allSubscriptionContent.find((subscriptionContent) =>
    subscription?.tagList?.includes(
      TAGS[`CLUB_${subscriptionContent.defaultQuantity}_NEW`]
    )
  )
