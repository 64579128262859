import { TAGS } from '~/utils/lists/order-tags'

export const SUBSCRIPTION_QUANTITY_OPTIONS = [
  {
    key: '12',
    label: '12 cans',
    tag: TAGS.CLUB_12_NEW,
  },
  {
    key: '24',
    label: '24 cans',
    tag: TAGS.CLUB_24_NEW,
  },
]

export const SUBSCRIPTION_TYPE_OPTIONS = [
  {
    key: 'default',
    field: 'defaultVariants',
    label: 'All varietals (red, white, and rosé)',
    tag: '',
  },
  {
    key: 'redOnly',
    field: 'defaultVariantsRedOnly',
    label: 'Red only (no white or rosé)',
    tag: TAGS.CLUB_RED_ONLY,
  },
  {
    key: 'noRed',
    field: 'defaultVariantsNoRed',
    label: 'White and rosé only (no red)',
    tag: TAGS.CLUB_NO_RED,
  },
]
