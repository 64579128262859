import Cookies from 'js-cookie'
import storage from '~/services/storage'
import {
  REFERRALS_COOKIE_HAS_PURCHASED,
  REFERRALS_STORAGE_BUBBLE_HIDDEN,
} from '~/utils/referrals'

const initialState = {
  alertBar: {
    notification: null,
    isDismissed: false,
  },
  aria: {
    live: null,
  },
  canClub: {
    clubDiscount: 0,
    customization: {
      error: null,
      isCustomized: false,
      isSaving: false,
      showError: false,
      subscriptionId: null,
      variants: {
        isFetching: false,
        ids: null,
      },
    },
    join: {
      interval: {
        length: null,
        unit: null,
      },
      step: 'type',
      steps: ['type', 'default', 'customize', 'review'],
      type: null,
    },
    products: {
      data: {},
      defaults: {},
      isSet: false,
    },
    quantity: null,
  },
  cartFlyout: {
    isOpen: false,
  },
  catalog: {
    packs: [],
    merch: [],
    kits: [],
    products: [],
    variants: [],
    subscriptions: [],
  },
  geolocation: {
    error: null,
    isFetching: false,
    isWarning: false,
    location: {},
  },
  giftCardDetails: null,
  groupOrder: {
    addons: [],
    addresses: [],
    details: {
      company: null,
      date: null,
      email: null,
      firstName: null,
      giftMessage: null,
      lastName: null,
      size: null,
      time: null,
    },
    error: null,
    estimateUrl: null,
    products: [],
    submitted: false,
  },
  productFilters: {
    'shop-wines': {
      wineType: [],
      diversityFilters: [],
      featuresFilters: [],
    },
  },
  referrals: {
    hasHiddenBubble: !!storage.getItem(REFERRALS_STORAGE_BUBBLE_HIDDEN),
    hasPurchased: !!Cookies.get(REFERRALS_COOKIE_HAS_PURCHASED),
  },
  reviews: {
    reviews: {
      data: {},
      fetched: [],
    },
    stats: {
      all: {
        data: {},
        hasFetched: false,
      },
      products: {
        data: {},
        hasFetched: false,
      },
      site: {
        data: {},
        hasFetched: false,
      },
    },
  },
}

export default initialState
