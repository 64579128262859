import {
  REFERRALS_SET_HAS_HIDDEN_BUBBLE,
  REFERRALS_SET_HAS_PURCHASED,
} from '~/redux/actions/types'

export const setReferralsHasHiddenBubble = (data) => ({
  type: REFERRALS_SET_HAS_HIDDEN_BUBBLE,
  payload: {
    data,
  },
})

export const setReferralsHasPurchased = (data) => ({
  type: REFERRALS_SET_HAS_PURCHASED,
  payload: {
    data,
  },
})
