/** @jsx jsx */
import { Box, Button, Heading, jsx, Text } from 'theme-ui'
import Modal from '~/components/Generic/Modal'
import { useGeolocation } from '~/hooks/components/use-geolocation'

const GeolocationModal = ({ onClose }) => {
  const { location } = useGeolocation()

  return (
    <Modal onClose={onClose} maxWidth="480px">
      <Box
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <Box bg="backgroundBright" p={2} pb="12px">
          <Heading as="p" variant="title2" sx={{ textAlign: 'center' }}>
            {'Heads up!'}
          </Heading>
        </Box>
        <Box bg="white" p={[2, 3]}>
          <Text as="p" variant="body2" mb={3}>
            {location.country === 'United States' &&
              `It looks like you are in ${location.region}! Due to wine laws, we can't ship here yet. Please ship to an address outside of the state or check back soon.`}
            {location.country !== 'United States' &&
              `It looks like you might be somewhere we can't ship yet. Please ship to an address within a US state.`}
          </Text>
          <Button onClick={() => onClose()} sx={{ width: '100%' }}>
            I understand
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default GeolocationModal
