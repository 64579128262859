import {
  CAN_CLUB_SET_CLUB_DISCOUNT,
  CAN_CLUB_CUSTOMIZATION_SET_ERROR,
  CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED,
  CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING,
  CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR,
  CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID,
  CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST,
  CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS,
  CAN_CLUB_JOIN_SET_INTERVAL,
  CAN_CLUB_JOIN_SET_STEP,
  CAN_CLUB_JOIN_SET_TYPE,
  CAN_CLUB_SET_PRODUCTS,
  CAN_CLUB_SET_PRODUCTS_DEFAULTS,
  CAN_CLUB_SET_QUANTITY,
  CAN_CLUB_UPDATE_PRODUCT_QUANTITY,
} from '~/redux/actions/types'
import { getClubDiscountFromSubscriptions } from '~/utils/subscriptions'

export const setClubDiscount = (subscriptions) => {
  const discount = getClubDiscountFromSubscriptions(subscriptions)

  return {
    type: CAN_CLUB_SET_CLUB_DISCOUNT,
    payload: {
      discount,
    },
  }
}

export const setCustomizationError = (error) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_SET_ERROR,
    payload: {
      error,
    },
  }
}

export const setCustomizationIsCustomized = (isCustomized) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED,
    payload: {
      isCustomized,
    },
  }
}

export const setCustomizationIsSaving = (isSaving) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING,
    payload: {
      isSaving,
    },
  }
}

export const setCustomizationShowError = (showError) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR,
    payload: {
      showError,
    },
  }
}

export const setCustomizationSubscriptionId = (subscriptionId) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID,
    payload: {
      subscriptionId,
    },
  }
}

export const customizationVariantsFetchRequest = () => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST,
  }
}

export const customizationVariantsFetchSuccess = (variantIds) => {
  return {
    type: CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS,
    payload: {
      variantIds,
    },
  }
}

export const setJoinInterval = (length, unit) => {
  return {
    type: CAN_CLUB_JOIN_SET_INTERVAL,
    payload: {
      length,
      unit,
    },
  }
}

export const setJoinStep = (step) => {
  return {
    type: CAN_CLUB_JOIN_SET_STEP,
    payload: {
      step,
    },
  }
}

export const setJoinType = (type) => {
  return {
    type: CAN_CLUB_JOIN_SET_TYPE,
    payload: {
      type,
    },
  }
}

export const setProducts = (products) => {
  return {
    type: CAN_CLUB_SET_PRODUCTS,
    payload: {
      products,
    },
  }
}

export const setProductsDefaults = (products) => {
  return {
    type: CAN_CLUB_SET_PRODUCTS_DEFAULTS,
    payload: {
      products,
    },
  }
}

export const setQuantity = (quantity) => {
  return {
    type: CAN_CLUB_SET_QUANTITY,
    payload: {
      quantity,
    },
  }
}

export const updateProductQuantity = (sku, quantity) => {
  return {
    type: CAN_CLUB_UPDATE_PRODUCT_QUANTITY,
    payload: {
      quantity,
      sku,
    },
  }
}

export const loadCustomizationVariantsBySkus =
  (skus) =>
  async (dispatch, getState, { chord }) => {
    const { auth } = getState()

    dispatch(customizationVariantsFetchRequest())

    try {
      await chord.oms
        .getVariantsBySkus(auth.token, skus)
        .then((response) => {
          const variantSkuIds = {}
          response?.variants?.forEach(({ sku, id }) => {
            variantSkuIds[sku] = id
          })

          dispatch(customizationVariantsFetchSuccess(variantSkuIds))
        })
        .catch((error) => {
          throw new Error(error)
        })
    } catch (error) {
      dispatch(
        setCustomizationError(
          'An unexpected error occured. Please refresh the page and try again.'
        )
      )
      dispatch(setCustomizationShowError(true))
    }
  }
