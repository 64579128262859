import axios from 'axios'

class IpbaseApi {
  async getLocation() {
    return await axios
      .get('https://api.ipbase.com/v2/info', {
        params: {
          apikey: process.env.GATSBY_IPBASE_APIKEY,
        },
      })
      .then((response) => {
        if (response.status !== 200 || !response.data?.data?.location) {
          throw new Error(response.statusText)
        }

        return response.data?.data?.location
      })
      .catch((error) => {
        console.error(error, {
          source: 'ipbase.getLocation',
        })
      })
  }
}

const ipbaseApi = new IpbaseApi()

export default ipbaseApi
