import { useEffect, useState } from 'react'
import { useCart, utils } from '@chordcommerce/gatsby-theme-autonomy'
import storage from '~/services/storage'

export const PROMO_STORAGE = 'maker-promos'

export function addPromoCodesToStorage(promoCodes) {
  storage.setItem(PROMO_STORAGE, promoCodes.join(' '))
}

// Promo codes can only be applied when the cart qualifies for the rules. For
// example, a promo code that discounts line items can only be applied when the
// cart has line items. A promo code that discounts subscription orders can only
// be applied when there are subscription items in the cart. For this reason,
// useUrlPromoCodes() stores promo codes that don't get applied in local
// storage. This function can then be run whenever the cart may now qualify,
// such as when new products are added to the cart.
export function applyPromoCodesFromStorage({ addPromoCode }) {
  const promoCodeStorage = storage.getItem(PROMO_STORAGE)

  if (promoCodeStorage) {
    const applyPromoCodes = async (promoCodes) => {
      const unappliedPromoCodes = []

      for (let i = 0; i < promoCodes.length; i++) {
        const promoCode = promoCodes[i]
        try {
          await new Promise((resolve) => setTimeout(resolve, 500))
          await addPromoCode({ promoCode })
        } catch (error) {
          if (error?.message && !error.message.includes('already')) {
            unappliedPromoCodes.push(promoCode)
          }
        }
      }

      storage.setItem(PROMO_STORAGE, unappliedPromoCodes.join(' '))
    }

    const storedPromoCodes = promoCodeStorage.split(' ')
    applyPromoCodes(storedPromoCodes)
  }
}

// Pull any promo codes from the URL parameter (multiple codes should be
// concatenated with '+'), store them, and attempt to apply them. If they're not
// able to be applied now, there will be attempts to reapply them later.
export function useUrlPromoCodes() {
  const { addPromoCode } = useCart()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const { getParam } = utils
    // @todo Deprecate aff due to collisions with Goaffpro attribution.
    const urlPromosParam =
      getParam('mwaff') || getParam('aff') || getParam('promo')

    if (urlPromosParam && !isInitialized) {
      storage.setItem(PROMO_STORAGE, urlPromosParam)
      applyPromoCodesFromStorage({ addPromoCode })
      setIsInitialized(true)
    }
  }, [addPromoCode, isInitialized])
}
