import { useCallback, useEffect, useState } from 'react'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { useCatalog } from '~/hooks/components/use-catalog'

// We frequently change the different wines ("parts") in a kit/bundle without
// changing the SKU of that bundle. Customers sometimes keep bundles in their
// cart for a long time (months even) before checking out. This can result in
// outdated wines getting purchased, which is mostly a problem when that wine
// has already sold out.

// The following compares, on initial page load, the cart's contents against the
// catalog content (originating from Contentful). If it finds a kit/bundle with
// different parts than the catalog content - or an outdated kit or variant SKU
// altogether - it will silently remove that line item from the cart.

export function useOutdatedBundleDetection() {
  const { cart, isLoaded, removeFromCart } = useCart()
  const { catalog } = useCatalog()
  const [hasChecked, setHasChecked] = useState()

  const { lineItems } = cart || {}
  const { kits, products } = catalog || {}

  const checkLineItems = useCallback(
    (lineItems) => {
      lineItems?.forEach(async (lineItem) => {
        const lineItemId = lineItem.id
        const parts = lineItem?.parts
        const sku = lineItem?.variant?.sku

        const catalogKit =
          parts?.length > 0 && kits.find((kit) => kit.sku === sku)

        if (
          (catalogKit && isLineItemBundleOutdated(lineItem, catalogKit)) ||
          (!catalogKit && isLineItemProductOutdated(sku, products))
        ) {
          await removeFromCart({ lineItemId })
        }
      })
    },
    [kits, products, removeFromCart]
  )

  useEffect(() => {
    if (!hasChecked && isLoaded && lineItems) {
      setHasChecked()
      checkLineItems(lineItems)
    }
  }, [isLoaded]) // eslint-disable-line

  return {}
}

// Compares the line item's parts to the current catalog kit/bundle content.
const isLineItemBundleOutdated = (lineItem, catalogKit) => {
  const lineItemPartSkus = lineItem.parts.reduce(
    (skus, part) => [...skus, ...Array(part.quantity).fill(part.sku)],
    []
  )
  const catalogKitSkus = catalogKit.products.map(
    (catalogKitProduct) => catalogKitProduct.variants[0].sku
  )

  // A crude way to compare two arrays of strings, but it works. :)
  return lineItemPartSkus.sort().join('') !== catalogKitSkus.sort().join('')
}

// Looks for the SKU in the current catalog product/variant content.
const isLineItemProductOutdated = (sku, catalogProducts) =>
  !catalogProducts.find(
    (product) => !!product.variants.find((variant) => variant.sku === sku)
  )
