import {
  GROUP_ORDER_REQUEST_ESTIMATE,
  GROUP_ORDER_SET_ERROR,
  GROUP_ORDER_SUBMIT,
  GROUP_ORDER_UPDATE_ADDONS,
  GROUP_ORDER_UPDATE_ADDRESSES,
  GROUP_ORDER_UPDATE_DETAILS,
  GROUP_ORDER_UPDATE_PRODUCTS,
} from '~/redux/actions/types'
import { track } from '~/services/analytics/client'

export const groupOrderMiddleware = (store) => (next) => (action) => {
  let result = next(action)
  let nextState = store.getState()

  switch (action.type) {
    case GROUP_ORDER_REQUEST_ESTIMATE:
      if (action.payload.totals?.productName) {
        track('Group Order Estimate Requested', {
          email: nextState.groupOrder.details.email,
          details: nextState.groupOrder.details,
          totals: action.payload.totals,
          url: action.payload.url,
        })
      }
      break

    case GROUP_ORDER_SET_ERROR:
      if (action.payload.error) {
        track('Group Order Estimate Error', {
          data: action.payload.data,
          email: nextState.groupOrder.details.email,
          error: action.payload.error,
        })

        console.error(action.payload.error, {
          source: 'GroupOrder',
        })
      }
      break

    case GROUP_ORDER_SUBMIT:
      track('Group Order Submitted', {
        data: nextState.groupOrder,
        email: nextState.groupOrder.details.email,
      })
      break

    case GROUP_ORDER_UPDATE_ADDONS:
      track('Group Order Addons Updated', {
        addons: action.payload.addons,
        email: nextState.groupOrder.details.email,
      })
      break

    case GROUP_ORDER_UPDATE_ADDRESSES:
      track('Group Order Addresses Uploaded', {
        addresses: action.payload.addresses,
        email: nextState.groupOrder.details.email,
      })
      break

    case GROUP_ORDER_UPDATE_DETAILS:
      track('Group Order Details Updated', {
        details: action.payload.details,
        email: action.payload.details.email,
      })
      break

    case GROUP_ORDER_UPDATE_PRODUCTS:
      track('Group Order Products Updated', {
        email: nextState.groupOrder.details.email,
        products: action.payload.products,
      })
      break

    default:
      return nextState
  }

  return result
}
