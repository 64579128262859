export const getClubDiscountedPrice = (discountPercentage, price) => {
  return price * ((100 - discountPercentage) / 100)
}

export const getClubItemTotalDiscountedPrice = (item) =>
  item.quantity * item.price.discount

export const getClubTotalPrices = (items) => {
  let discount = 0
  let regular = 0

  Object.entries(items).forEach(([sku, item]) => {
    discount = discount + item.price.discount * item.quantity
    regular = regular + item.price.regular * item.quantity
  })

  return {
    discount,
    regular,
  }
}
