import {
  CAN_CLUB_SET_CLUB_DISCOUNT,
  CAN_CLUB_CUSTOMIZATION_SET_ERROR,
  CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED,
  CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING,
  CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR,
  CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID,
  CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST,
  CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS,
  CAN_CLUB_JOIN_SET_INTERVAL,
  CAN_CLUB_JOIN_SET_STEP,
  CAN_CLUB_JOIN_SET_TYPE,
  CAN_CLUB_SET_PRODUCTS,
  CAN_CLUB_SET_PRODUCTS_DEFAULTS,
  CAN_CLUB_SET_QUANTITY,
  CAN_CLUB_UPDATE_PRODUCT_QUANTITY,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const canClub = (state = initialState.canClub, action) => {
  switch (action.type) {
    case CAN_CLUB_SET_CLUB_DISCOUNT:
      return {
        ...state,
        clubDiscount: action.payload.discount,
      }

    case CAN_CLUB_CUSTOMIZATION_SET_ERROR:
      return {
        ...state,
        customization: {
          ...state.customization,
          error: action.payload.error,
        },
      }

    case CAN_CLUB_CUSTOMIZATION_SET_IS_CUSTOMIZED:
      return {
        ...state,
        customization: {
          ...state.customization,
          isCustomized: action.payload.isCustomized,
        },
      }

    case CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING:
      return {
        ...state,
        customization: {
          ...state.customization,
          isSaving: action.payload.isSaving,
        },
      }

    case CAN_CLUB_CUSTOMIZATION_SET_SHOW_ERROR:
      return {
        ...state,
        customization: {
          ...state.customization,
          showError: action.payload.showError,
        },
      }

    case CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID:
      return {
        ...state,
        customization: {
          ...state.customization,
          subscriptionId: action.payload.subscriptionId,
        },
      }

    case CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_REQUEST:
      return {
        ...state,
        customization: {
          ...state.customization,
          variants: {
            ...state.customization.variants,
            isFetching: true,
          },
        },
      }

    case CAN_CLUB_CUSTOMIZATION_VARIANTS_FETCH_SUCCESS:
      return {
        ...state,
        customization: {
          ...state.customization,
          variants: {
            ...state.customization.variants,
            ids: {
              ...state.customization.variants.ids,
              ...action.payload.variantIds,
            },
            isFetching: false,
          },
        },
      }

    case CAN_CLUB_JOIN_SET_INTERVAL:
      return {
        ...state,
        join: {
          ...state.join,
          interval: {
            ...state.join.interval,
            length: action.payload.length,
            unit: action.payload.unit,
          },
        },
      }

    case CAN_CLUB_JOIN_SET_STEP:
      return {
        ...state,
        join: {
          ...state.join,
          step: action.payload.step,
        },
      }

    case CAN_CLUB_JOIN_SET_TYPE:
      return {
        ...state,
        join: {
          ...state.join,
          type: action.payload.type,
        },
      }

    case CAN_CLUB_SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          isSet: true,
          data: {
            ...state.products.data,
            ...action.payload.products,
          },
        },
      }

    case CAN_CLUB_SET_PRODUCTS_DEFAULTS:
      return {
        ...state,
        products: {
          ...state.products,
          defaults: {
            ...state.products.defaults,
            ...action.payload.products,
          },
        },
      }

    case CAN_CLUB_SET_QUANTITY:
      return {
        ...state,
        quantity: action.payload.quantity,
      }

    case CAN_CLUB_UPDATE_PRODUCT_QUANTITY:
      const { quantity, sku } = action.payload

      return {
        ...state,
        products: {
          ...state.products,
          data: {
            ...state.products.data,
            [sku]: {
              ...state.products.data[sku],
              quantity: quantity,
            },
          },
        },
      }

    default:
      return state
  }
}

export default canClub
