import {
  GEOLOCATION_FETCH_REQUEST,
  GEOLOCATION_FETCH_SUCCESS,
  GEOLOCATION_FETCH_ERROR,
  GEOLOCATION_WARNING_ENABLE,
  GEOLOCATION_WARNING_DISABLE,
} from '~/redux/actions/types'
import ipbaseApi from '~/services/ipbase/client'
import storage from '~/services/storage'

// Manually set these in storage to preview the functionality.
const SIMULATED_STORAGE_STATE = 'maker-geo-state'
const SIMULATED_STORAGE_COUNTRY = 'maker-geo-country'

export const fetchGeolocationRequest = () => ({
  type: GEOLOCATION_FETCH_REQUEST,
})

export const fetchGeolocationSuccess = (data) => ({
  type: GEOLOCATION_FETCH_SUCCESS,
  payload: {
    data,
  },
})

export const fetchGeolocationError = (error) => ({
  type: GEOLOCATION_FETCH_ERROR,
  payload: error,
  error: true,
})

export const enableGeolocationWarning = () => ({
  type: GEOLOCATION_WARNING_ENABLE,
})

export const disableGeolocationWarning = () => ({
  type: GEOLOCATION_WARNING_DISABLE,
})

export const fetchGeolocation =
  () =>
  async (dispatch, getState, { chord }) => {
    const simulatedState = storage.getItem(SIMULATED_STORAGE_STATE)
    const simulatedCountry = storage.getItem(SIMULATED_STORAGE_COUNTRY)

    dispatch(fetchGeolocationRequest())

    if (simulatedState || simulatedCountry) {
      return dispatch(
        fetchGeolocationSuccess({
          location: {
            region: { name: simulatedState || 'California' },
            country: { name: simulatedCountry || 'United States' },
          },
        })
      )
    }

    ipbaseApi
      .getLocation()
      .then((data) => {
        if (data.country && data.region) {
          dispatch(fetchGeolocationSuccess({ location: data }))
        }
      })
      .catch((error) => {
        dispatch(fetchGeolocationError(error))
      })
  }
