import {
  GIFT_CARDS_DETAILS_ADD,
  GIFT_CARDS_DETAILS_REMOVE,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const giftCardDetails = (state = initialState.giftCardDetails, action) => {
  switch (action.type) {
    case GIFT_CARDS_DETAILS_ADD:
      return {
        ...action.payload,
      }

    case GIFT_CARDS_DETAILS_REMOVE:
      return null

    default:
      return state
  }
}

export default giftCardDetails
