import axios from 'axios'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import storage from '~/services/storage'
import { isProduction } from '~/utils/misc'

const SHAREASALE_PARAM = 'sscid'

const SHAREASALE_STORAGE = 'maker-sscid'

// Passes the client-side ID to server-side ShareASale order reporting.
export const createShareasaleOrder = async (orderNumber) => {
  const id = storage.getItem(SHAREASALE_STORAGE)

  if (!id || !orderNumber || !isProduction()) return

  // @see ~/functions/shareasale.mjs
  axios
    .get('/.netlify/functions/shareasale', { params: { id, orderNumber } })
    .then((response) => {
      if (response.status === 200) {
        // Ensures the order is only reported once.
        storage.removeItem(SHAREASALE_STORAGE)
      } else {
        throw new Error('ShareASale function failed')
      }
    })
    .catch((error) => {
      console.error(error, {
        source: 'shareasale.createOrder',
      })
    })
}

// Stores the ID from the incoming parameter.
export const setShareasaleId = () => {
  if (!process.env.GATSBY_SHAREASALE_MERCHANT_ID) return

  const { getParam } = utils
  const id = getParam(SHAREASALE_PARAM)

  if (id) {
    storage.setItem(SHAREASALE_STORAGE, id)
  }
}
