import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions/aria'
import * as selectors from '../../redux/selectors/aria'

export function useAria() {
  const dispatch = useDispatch()

  const ariaLive = useSelector(selectors.getAriaLive)

  const updateAriaLive = useMemo(
    () => bindActionCreators(actions.updateAriaLive, dispatch),
    [dispatch]
  )

  return {
    ariaLive,
    updateAriaLive,
  }
}
