import { useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/geolocation'
import {
  getGeolocationIsWarning,
  getGeolocationLocation,
} from '~/redux/selectors/geolocation'
import { STATES_SHIPPABLE } from '~/utils/lists/us-states'

export function useGeolocation() {
  const dispatch = useDispatch()

  const isWarning = useSelector(getGeolocationIsWarning)
  const location = useSelector(getGeolocationLocation)

  const fetchGeolocation = useMemo(
    () => bindActionCreators(actions.fetchGeolocation, dispatch),
    [dispatch]
  )

  const enableGeolocationWarning = useMemo(
    () => bindActionCreators(actions.enableGeolocationWarning, dispatch),
    [dispatch]
  )

  const disableGeolocationWarning = useMemo(
    () => bindActionCreators(actions.disableGeolocationWarning, dispatch),
    [dispatch]
  )

  const checkGeolocation = () => !location?.region && fetchGeolocation()

  useEffect(() => {
    if (
      location?.region &&
      location?.country &&
      (!Object.values(STATES_SHIPPABLE).includes(location?.region) ||
        location?.country !== 'United States')
    ) {
      enableGeolocationWarning()
    }
  }, [enableGeolocationWarning, location])

  return {
    checkGeolocation,
    disableGeolocationWarning,
    isWarning,
    location,
  }
}
