export const sortActiveSubscriptions = (subscriptions) =>
  subscriptions
    ?.filter((subscription) => subscription.state === 'active')
    ?.sort((a, b) => (a.actionableDate > b.actionableDate ? 1 : -1))

export const sortProductsByCategory = (categoryNames, products) => {
  let sortedProducts = []

  categoryNames.forEach((categoryName) => {
    let categoryProducts = []

    Object.entries(products).forEach(([sku, item]) => {
      if (item?.wineType?.includes(categoryName)) {
        categoryProducts.push(item)
      }
    })

    categoryProducts.sort((a, b) => (a.name > b.name ? 1 : -1))
    sortedProducts = [...sortedProducts, ...categoryProducts]
  })

  return sortedProducts
}
