/** @jsx jsx */
import { useEffect, useRef } from 'react'
import { Box, Button, jsx } from 'theme-ui'
import useOnClickOutside from '~/hooks/components/use-on-click-outside'
import CloseIcon from '~/assets/images/icons/close.inline.svg'

const Modal = ({ children, maxWidth, includeCloseButton = true, onClose }) => {
  const scrollLockedClass = 'layout-locked'
  const closeButtonRef = useRef()
  const modalContentRef = useRef()

  const handleClose = () => {
    document.body.classList.remove(scrollLockedClass)
    onClose()
  }

  useOnClickOutside(modalContentRef, () => handleClose())

  useEffect(() => {
    if (includeCloseButton) {
      closeButtonRef.current.focus()
    }
    document.body.classList.add(scrollLockedClass)
  }, [closeButtonRef, includeCloseButton, scrollLockedClass])

  return (
    <Box sx={{ position: 'relative', zIndex: '1000' }}>
      <Box
        sx={{
          backgroundColor: 'black',
          bottom: '0',
          left: '0',
          opacity: '0.4',
          position: 'fixed',
          right: '0',
          top: '0',
          zIndex: '1',
        }}
      />
      <Box
        px={[2, 8]}
        py={10}
        sx={{
          bottom: '0',
          left: '0',
          overflowY: 'scroll',
          position: 'fixed',
          right: '0',
          top: '0',
          zIndex: '2',
        }}
      >
        <Box
          ref={modalContentRef}
          sx={{
            margin: '0 auto',
            maxWidth: maxWidth,
            position: 'relative',
          }}
        >
          {includeCloseButton && (
            <Button
              variant="reset"
              onClick={() => handleClose()}
              ref={closeButtonRef}
              sx={{
                position: 'absolute',
                height: ['40px', '48px'],
                right: '0',
                top: '0',
                width: '48px',
                zIndex: '1',
              }}
            >
              <CloseIcon sx={{ height: '16px', width: '16px' }} />
            </Button>
          )}
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default Modal
