import {
  GEOLOCATION_FETCH_REQUEST,
  GEOLOCATION_FETCH_SUCCESS,
  GEOLOCATION_FETCH_ERROR,
  GEOLOCATION_WARNING_ENABLE,
  GEOLOCATION_WARNING_DISABLE,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const geolocation = (state = initialState.geolocation, action) => {
  switch (action.type) {
    case GEOLOCATION_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isWarning: false,
        location: {},
      }

    case GEOLOCATION_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        location: {
          ...state.location,
          country: action.payload.data.location.country?.name,
          region: action.payload.data.location.region?.name,
        },
      }

    case GEOLOCATION_FETCH_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }

    case GEOLOCATION_WARNING_ENABLE:
      return {
        ...state,
        isWarning: true,
      }

    case GEOLOCATION_WARNING_DISABLE:
      return {
        ...state,
        isWarning: false,
      }

    default:
      return state
  }
}

export default geolocation
