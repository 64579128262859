import { getProductContentFromSku } from '~/utils/products'
import { getClubDiscountedPrice } from './pricing'

// Gets all available club product information from the Contentful subscription.
const getClubAvailableItems = (subscriptionContent, allProductContent) => {
  const { availableVariants, discountPercentage } = subscriptionContent || {}
  const items = {}

  availableVariants?.forEach((variant) => {
    const {
      clubDescription,
      mainImage,
      mainImage_thumbnail,
      price,
      sku,
      wineType,
    } = variant
    const product = getProductContentFromSku(sku, allProductContent)

    if (product) {
      const { name } = product

      items[sku] = {
        description: clubDescription,
        image: mainImage,
        name: name,
        quantity: 0,
        price: {
          discount: getClubDiscountedPrice(discountPercentage, price),
          regular: price,
        },
        product,
        sku,
        thumbnail: mainImage_thumbnail,
        wineType,
      }
    }
  })

  return items
}
// Gets the default items from the Contentful subscription.
export const getClubJoinDefaultItems = (
  subscriptionContent,
  allProductContent,
  defaultVariantsField = 'defaultVariants'
) => {
  const items = getClubAvailableItems(subscriptionContent, allProductContent)
  const defaultVariants = subscriptionContent[defaultVariantsField]

  defaultVariants.forEach((variant) => {
    const { sku } = variant

    if (items.hasOwnProperty(sku)) {
      items[sku].quantity = items[sku].quantity + 1
    }
  })

  return items
}

// Gets the subscription items from cart line items.
export const getClubCartDefaultItems = (
  lineItems,
  subscriptionContent,
  allProductContent
) => {
  const items = getClubAvailableItems(subscriptionContent, allProductContent)

  if (lineItems && lineItems.length) {
    for (let i = 0; i < lineItems.length; i++) {
      for (let j = 0; j < lineItems[i].subscriptionLineItems.length; j++) {
        const { sku, quantity } = lineItems[i].subscriptionLineItems[j]

        if (items.hasOwnProperty(sku)) {
          items[sku].quantity = quantity
        }
      }
    }
  }

  return items
}

// Gets the subscription items from the OMS subscription being customized.
export const getClubInstallmentDefaultItems = (
  subscription,
  subscriptionContent,
  allProductContent
) => {
  const items = getClubAvailableItems(subscriptionContent, allProductContent)
  const { lineItems } = subscription || {}

  if (lineItems && lineItems.length) {
    for (let i = 0; i < lineItems.length; i++) {
      const { sku, quantity } = lineItems[i]

      if (items.hasOwnProperty(sku)) {
        items[sku].quantity = quantity
      }
    }
  }

  return items
}

// Gets the subscription items from a kit (e.g. landing page upsell).
export const getClubKitDefaultItems = (
  kitContent,
  subscriptionContent,
  allProductContent
) => {
  const items = getClubAvailableItems(subscriptionContent, allProductContent)
  const { products } = kitContent

  if (products && products.length) {
    for (let i = 0; i < products.length; i++) {
      const { sku } = products[i].variants[0]

      // Let it slide if the kit has a product that isn't currently available.
      if (!items.hasOwnProperty(sku)) items[sku] = { quantity: 0 }

      items[sku].quantity = items[sku].quantity + 1
    }
  }

  return items
}

export const getClubJoinNewLineItems = (products, interval) => {
  const skus = Object.keys(products)
  const items = []

  for (let i = 0; i < skus.length; i++) {
    const sku = skus[i]
    const { quantity } = products[sku]

    if (quantity && quantity > 0) {
      items.push({
        sku,
        quantity,
        subscriptionLineItemsAttributes: [
          {
            intervalLength: interval.length,
            intervalUnits: interval.unit,
          },
        ],
      })
    }
  }

  return items
}

// Updates OMS subscription line items with the customization selections.
export const getClubUpdatedInstallmentLineItems = (
  subscription,
  products,
  variantIds
) => {
  const { lineItems } = subscription
  const productSkus = Object.keys(products)
  const newLineItems = []
  const updatedSkus = []

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i]
    const { sku } = lineItem

    if (
      products.hasOwnProperty(sku) &&
      products[sku].quantity > 0 &&
      !updatedSkus.includes(sku)
    ) {
      lineItem.quantity = products[sku].quantity
    } else {
      lineItem._destroy = true
    }

    updatedSkus.push(sku)
    newLineItems.push(lineItem)
  }

  for (let i = 0; i < productSkus.length; i++) {
    const product = products[productSkus[i]]
    const { quantity, sku } = product

    if (!updatedSkus.includes(sku) && quantity > 0) {
      newLineItems.push({
        quantity,
        sku,
        subscribableId: variantIds[sku],
      })
    }
  }

  return newLineItems
}

// Determines whether or not the items in a cart belong to the club.
export const getDoesCartHaveClubItems = (lineItems) =>
  !!lineItems?.filter((item) => item.subscriptionLineItems.length > 0).length
