export const getAvailableProductVariants = (product) =>
  product?.variants?.filter((variant) => !variant?.noIndividualPurchase) || []

export const getProductContentFromSku = (sku, allProductContent) => {
  for (let i = 0; i < allProductContent.length; i++) {
    for (let j = 0; j < allProductContent[i]?.variants?.length; j++) {
      if (allProductContent[i].variants[j].sku === sku) {
        return allProductContent[i]
      }
    }
  }
}

export const getProductVariantFromSku = (sku, product) => {
  for (let i = 0; i < product.variants.length; i++) {
    if (product.variants[i].sku === sku) {
      return product.variants[i]
    }
  }
}

export const getFormattedBottleCount = (canCount) => {
  const fullBottles = Math.floor(canCount / 3)
  const remainder = canCount % 3

  switch (remainder) {
    case 0:
      return `${fullBottles}`

    case 1:
      return `${fullBottles} ⅓`

    case 2:
      return `${fullBottles} ⅔`

    default:
      break
  }
}

// Massages data as needed for serialization within analytics calls.
export const getViewedProductFromSlug = (catalog, slug) => {
  const { kits, merch, packs, products } = catalog

  const listing = [...packs, ...merch].find((listing) => listing.slug === slug)
  const s =
    listing?.kits?.[0]?.slug ||
    listing?.kit?.slug ||
    listing?.product?.slug ||
    slug

  const kit = kits.find((kit) => kit.slug === s)

  if (kit) {
    return {
      quantity: 1,
      variant: {
        images: [
          {
            largeUrl: kit?.mainImage?.gatsbyImageData?.images?.fallback?.src,
          },
        ],
        name: kit.name,
        price: kit.price,
        sku: kit.sku,
        slug: kit.slug,
      },
    }
  }

  const product = products.find((product) => product.slug === s)

  if (product) {
    return {
      quantity: 1,
      variant: {
        images: [
          {
            largeUrl:
              product?.mainImage?.gatsbyImageData?.images?.fallback?.src,
          },
        ],
        name: product.name,
        price: product?.variants?.[0]?.price,
        sku: product?.variants?.[0]?.sku,
        slug: product.slug,
      },
    }
  }
}
