import React, { Fragment } from 'react'
import GeolocationModal from './Modal'
import { useGeolocation } from '~/hooks/components/use-geolocation'

const GeolocationWrapper = () => {
  const { disableGeolocationWarning, isWarning } = useGeolocation()

  if (isWarning) {
    return <GeolocationModal onClose={() => disableGeolocationWarning()} />
  }

  return <Fragment />
}

export default GeolocationWrapper
