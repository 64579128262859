import { ARIA_LIVE_UPDATE } from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const aria = (state = initialState.aria, action) => {
  switch (action.type) {
    case ARIA_LIVE_UPDATE:
      return {
        ...state,
        live: action.payload.message,
      }

    default:
      return state
  }
}

export default aria
