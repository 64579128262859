import { FINALIZE_CHECKOUT_SUCCESS } from '@chordcommerce/react-autonomy/dist/redux/actions/types'
import {
  CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING,
  CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID,
  CAN_CLUB_JOIN_SET_INTERVAL,
  CAN_CLUB_JOIN_SET_STEP,
  CAN_CLUB_UPDATE_PRODUCT_QUANTITY,
} from '~/redux/actions/types'
import {
  trackClubCustomizeSaved,
  trackClubCustomizeStarted,
  trackClubCustomizeUpdated,
  trackClubDefaultSelectionStarted,
  trackClubJoinStarted,
  trackClubReviewStarted,
  trackClubSignupCompleted,
} from '~/services/analytics/client'
import { getDoesCartHaveClubItems } from '~/utils/subscriptions'

export const canClubMiddleware = (store) => (next) => (action) => {
  let result = next(action)
  let nextState = store.getState()

  switch (action.type) {
    case CAN_CLUB_JOIN_SET_INTERVAL:
      trackClubJoinStarted()
      break

    case CAN_CLUB_JOIN_SET_STEP:
      switch (action.payload.step) {
        case nextState.canClub.join.steps[1]:
          trackClubDefaultSelectionStarted({
            type: nextState?.canClub?.join?.type,
          })
          break

        case nextState.canClub.join.steps[2]:
          trackClubCustomizeStarted({
            items: nextState?.canClub?.products?.data,
            type: nextState?.canClub?.join?.type,
          })
          break

        case nextState.canClub.join.steps[3]:
          trackClubReviewStarted({
            items: nextState.canClub.products.data,
            type: nextState?.canClub?.join?.type,
          })
          break

        default:
          break
      }
      break

    case CAN_CLUB_UPDATE_PRODUCT_QUANTITY:
      const { quantity, sku } = action.payload

      trackClubCustomizeUpdated({
        updated: { quantity, sku },
        items: nextState.canClub.products.data,
      })
      break

    case CAN_CLUB_CUSTOMIZATION_SET_IS_SAVING:
      if (!action.payload.isSaving && !nextState.canClub.customization.error) {
        trackClubCustomizeSaved({
          items: nextState.canClub.products.data,
          user: nextState.user,
        })
      }
      break

    case CAN_CLUB_CUSTOMIZATION_SET_SUBSCRIPTION_ID:
      trackClubCustomizeStarted({
        items: nextState.canClub.products.data,
        subscriptionId: action.payload.subscriptionId,
      })
      break

    case FINALIZE_CHECKOUT_SUCCESS:
      if (getDoesCartHaveClubItems(action?.cart?.lineItems)) {
        trackClubSignupCompleted({ cart: action.cart })
      }
      break

    default:
      break
  }

  return result
}
