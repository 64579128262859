import { identify } from '@chordcommerce/chord-js-autonomy/dist/analytics/utils'
import { LOAD_USER_SUCCESS } from '@chordcommerce/react-autonomy/dist/redux/actions/types'

export const authMiddleware = (store) => (next) => (action) => {
  let result = next(action)

  switch (action.type) {
    case LOAD_USER_SUCCESS:
      const userId = action?.payload?.data?.id
      const email = action?.payload?.data?.email

      if (userId && email) {
        identify(userId, { email })
      }
      break

    default:
      break
  }

  return result
}
