import {
  GROUP_ORDER_REQUEST_ESTIMATE,
  GROUP_ORDER_SET_ERROR,
  GROUP_ORDER_SUBMIT,
  GROUP_ORDER_UPDATE_ADDONS,
  GROUP_ORDER_UPDATE_ADDRESSES,
  GROUP_ORDER_UPDATE_DETAILS,
  GROUP_ORDER_UPDATE_PRODUCTS,
} from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const groupOrder = (state = initialState.groupOrder, action) => {
  switch (action.type) {
    case GROUP_ORDER_REQUEST_ESTIMATE:
      return {
        ...state,
        estimateUrl: action.payload.url,
      }

    case GROUP_ORDER_SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }

    case GROUP_ORDER_SUBMIT:
      return {
        ...state,
        submitted: true,
      }

    case GROUP_ORDER_UPDATE_ADDONS:
      return {
        ...state,
        addons: [...action.payload.addons],
        estimateUrl:
          action.payload.addons?.[0]?.sku !== state.addons?.[0]?.sku
            ? null
            : state.estimateUrl,
      }

    case GROUP_ORDER_UPDATE_ADDRESSES:
      return {
        ...state,
        addresses: [...action.payload.addresses],
      }

    case GROUP_ORDER_UPDATE_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload.details,
        },
      }

    case GROUP_ORDER_UPDATE_PRODUCTS:
      return {
        ...state,
        estimateUrl:
          action.payload.products?.[0]?.sku !== state.products?.[0]?.sku
            ? null
            : state.estimateUrl,
        products: [...action.payload.products],
      }

    default:
      return state
  }
}

export default groupOrder
