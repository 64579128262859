// @see https://usehooks.com/useOnClickOutside/
import { useEffect, useState } from 'react'

function useOnClickOutside(ref, handler) {
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    setIsEnabled(true)
  }, [])

  useEffect(() => {
    const listener = (event) => {
      if (isEnabled && ref.current && !ref.current.contains(event.target)) {
        handler(event)
      }
    }

    document.addEventListener('click', listener)

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [ref, handler, isEnabled])
}

export default useOnClickOutside
