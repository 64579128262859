import { useSelector } from 'react-redux'
import { getCatalog } from '~/redux/selectors/catalog'

export function useAddToCart() {
  const catalog = useSelector(getCatalog)

  const getAddToCartDataBySku = (sku) => {
    if (!sku) return {}

    for (let i = 0; i < catalog?.kits?.length; i++) {
      const kit = catalog.kits[i]

      if (kit.sku === sku) {
        // Needed to properly link a kit line item to its pack/merch listing.
        const listing = [...catalog?.packs, ...catalog?.merch].find(
          (pack) => pack?.kits?.filter((pk) => pk?.sku === sku).length > 0
        )

        return {
          image: kit.mainImage,
          listingSlug: listing?.slug,
          name: listing?.name,
          parts: getAddToCartKitParts(kit),
          postPromoPrice: kit.postPromoPrice,
          postPromoPrice24: kit.postPromoPrice24,
          postPromoPriceCodes:
            kit.postPromoPriceCodes &&
            JSON.parse(kit.postPromoPriceCodes.internal.content),
          price: kit.price,
          sku: kit.sku,
        }
      }
    }

    for (let i = 0; i < catalog?.variants?.length; i++) {
      const variant = catalog.variants[i]

      if (variant.sku === sku) {
        return {
          image: variant.mainImage,
          price: variant.price,
          sku: variant.sku,
        }
      }
    }
  }

  const getAddToCartKitParts = (kit) => {
    let quantities = {}

    kit.products.forEach((product) => {
      const variant = product.variants[0]

      quantities[variant.sku] = quantities[variant.sku] || 0
      quantities[variant.sku] = quantities[variant.sku] + 1
    })

    return Object.keys(quantities).map((sku) => ({
      quantity: quantities[sku],
      sku,
    }))
  }

  return {
    getAddToCartDataBySku,
  }
}
