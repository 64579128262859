import { ALERT_BAR_DISMISS, ALERT_BAR_UPDATE } from '~/redux/actions/types'
import initialState from '~/redux/store/initial-state'

const alertBar = (state = initialState.alertBar, action) => {
  switch (action.type) {
    case ALERT_BAR_DISMISS:
      return {
        ...state,
        isDismissed: true,
      }

    case ALERT_BAR_UPDATE:
      return {
        ...state,
        notification: action.payload.notification,
      }

    default:
      return state
  }
}

export default alertBar
