/** @jsx jsx */
import { Box, jsx } from 'theme-ui'

const VisualyHidden = ({ as = 'div', children }) => {
  return (
    <Box
      as={as}
      sx={{
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        whiteSpace: 'nowrap',
        width: '1px',
      }}
    >
      {children}
    </Box>
  )
}

export default VisualyHidden
