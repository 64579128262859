/** @jsx jsx */
import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { jsx } from 'theme-ui'

// @see https://github.com/anujtenani/goaffpro/wiki/Custom-Integration-advanced-guide
const GoaffproScript = () => {
  if (!process.env.GATSBY_GOAFFPRO_SHOP_KEY) return <Fragment />

  return (
    <Helmet>
      <script
        type="text/javascript"
        src={`https://api.goaffpro.com/loader.js?shop=${process.env.GATSBY_GOAFFPRO_SHOP_KEY}`}
        async
      ></script>
    </Helmet>
  )
}

export default GoaffproScript
