import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '~/redux/actions/can-club'
import * as selectors from '~/redux/selectors/can-club'
import { SUBSCRIPTION_TYPE_OPTIONS } from '~/utils/subscriptions'

export function useCanClub() {
  const dispatch = useDispatch()

  const actionDispatches = useMemo(
    () => bindActionCreators(actions, dispatch),
    [dispatch]
  )

  const selectorFunctions = {
    clubDiscount: useSelector(selectors.getClubDiscount),
    customizationError: useSelector(selectors.getCustomizationError),
    customizationIsCustomized: useSelector(
      selectors.getCustomizationIsCustomized
    ),
    customizationIsSaving: useSelector(selectors.getCustomizationIsSaving),
    customizationShowError: useSelector(selectors.getCustomizationShowError),
    customizationVariantsIsFetching: useSelector(
      selectors.getCustomizationVariantsIsFetching
    ),
    customizationVariantsIds: useSelector(
      selectors.getCustomizationVariantsIds
    ),
    joinInterval: useSelector(selectors.getJoinInterval),
    joinStep: useSelector(selectors.getJoinStep),
    joinSteps: useSelector(selectors.getJoinSteps),
    joinType: useSelector(selectors.getJoinType),
    products: useSelector(selectors.getProducts),
    productsDefaults: useSelector(selectors.getProductsDefaults),
    productsIsSet: useSelector(selectors.getProductsIsSet),
    quantity: useSelector(selectors.getQuantity),
  }

  return {
    ...actionDispatches,
    ...selectorFunctions,
    joinTypeOption: SUBSCRIPTION_TYPE_OPTIONS.find(
      ({ key }) => key === selectorFunctions.joinType
    ),
    SUBSCRIPTION_TYPE_OPTIONS,
  }
}
