import React, { Fragment, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useAuth, useSubscriptions } from '@chordcommerce/gatsby-theme-autonomy'
import { useCanClub } from '~/hooks/components/use-can-club'
import { useReferrals } from '~/hooks/components/use-referrals'

const LayoutWrapperClubDiscount = () => {
  const { isLoggedIn } = useAuth()

  const subscriptionsState = useSelector(
    (state) => state.subscriptions,
    shallowEqual
  )

  if (!isLoggedIn) return <Fragment />

  // Dodges bug while logging in. For whatever reason, loading and success
  // weren't yet present as useSubscriptions() was being called.
  if (!subscriptionsState?.loading || !subscriptionsState?.success) {
    return <Fragment />
  }

  return <LayoutWrapperClubDiscountSetter />
}

// Makes the user's Can Club discount available to every page.
const LayoutWrapperClubDiscountSetter = () => {
  const { clubDiscount, setClubDiscount } = useCanClub()
  const { setReferralsHasPurchased } = useReferrals()
  const { isLoaded, subscriptions } = useSubscriptions()

  useEffect(() => {
    if (isLoaded && subscriptions.length > 0 && !clubDiscount) {
      setClubDiscount(subscriptions)
      setReferralsHasPurchased(true)
    }
  }, [
    clubDiscount,
    isLoaded,
    setClubDiscount,
    setReferralsHasPurchased,
    subscriptions,
  ])

  return <Fragment />
}

export default LayoutWrapperClubDiscount
